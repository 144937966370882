var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "권한",
                tableId: "table",
                columnSetting: false,
                usePaging: false,
                isFullScreen: false,
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                isExcelDown: false,
                filtering: false,
              },
              on: { rowClick: _vm.rowClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "LBLSEARCH", icon: "search" },
                        on: { btnClicked: _vm.getGrpList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                { slot: "table-header-append" },
                [
                  _c("c-select", {
                    attrs: {
                      codeGroupCd: "DEVICE_CD",
                      codeAttrVal1: "Y",
                      type: "none",
                      itemText: "codeName",
                      itemValue: "code",
                      name: "deviceTypeCd",
                      label: "",
                    },
                    on: { input: _vm.getGrpList },
                    model: {
                      value: _vm.searchParam.deviceTypeCd,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParam, "deviceTypeCd", $$v)
                      },
                      expression: "searchParam.deviceTypeCd",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLDETAIL" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: { label: "LBLREG", icon: "add" },
                                on: { btnClicked: _vm.addGrp },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          _vm.deleteable &&
                          _vm.data.defaultFlag == "N"
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.deleteUrl,
                                  isSubmit: true,
                                  param: _vm.data,
                                  mappingType: "DELETE",
                                  label: "LBLREMOVE",
                                  icon: "remove",
                                },
                                on: {
                                  beforeAction: _vm.deleteGrp,
                                  btnCallback: _vm.deleteCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          _vm.saveable &&
                          _vm.data.defaultFlag == "N"
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.saveType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveGrp,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "권한명",
                            name: "sysAuthGrpName",
                          },
                          model: {
                            value: _vm.data.sysAuthGrpName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "sysAuthGrpName", $$v)
                            },
                            expression: "data.sysAuthGrpName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: false,
                            codeGroupCd: "DEVICE_CD",
                            codeAttrVal1: "Y",
                            type: "edit",
                            required: true,
                            itemText: "codeName",
                            itemValue: "code",
                            name: "deviceTypeCd",
                            label: "디바이스구분",
                          },
                          model: {
                            value: _vm.data.deviceTypeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "deviceTypeCd", $$v)
                            },
                            expression: "data.deviceTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: true,
                            label: "권한 코드",
                            name: "sysAuthGrpId",
                          },
                          model: {
                            value: _vm.data.sysAuthGrpId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "sysAuthGrpId", $$v)
                            },
                            expression: "data.sysAuthGrpId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "설명",
                            name: "remark",
                            rows: 3,
                          },
                          model: {
                            value: _vm.data.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "remark", $$v)
                            },
                            expression: "data.remark",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.dashboardItems,
                            type: "edit",
                            itemText: "menuNm",
                            itemValue: "sysMenuId",
                            name: "dashboardId",
                            label: "기본 대시보드(메인)",
                          },
                          model: {
                            value: _vm.data.dashboardId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "dashboardId", $$v)
                            },
                            expression: "data.dashboardId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            type: "number",
                            label: "대시보드 우선순위",
                            name: "dashboardOrder",
                          },
                          model: {
                            value: _vm.data.dashboardOrder,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "dashboardOrder", $$v)
                            },
                            expression: "data.dashboardOrder",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            isFlag: true,
                            label: "LBLUSEFLAG",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }